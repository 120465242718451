/** @format */
/* eslint-disable react/prop-types */

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import AlertModal from 'apps/wms/components/forms/Alert'
import { ReportDataContext } from 'apps/wms/stores/ReportDataStore'
import { REPORT_ITEM_STATUS, REPORT_STATUS, REPORT_TYPE } from 'apps/wms/utils/enum'
import { useWindowSize } from 'apps/wms/utils/hooks'
import { Card, FlexView, Modal } from 'components/common'
import ReportActions from './actions'
import {
	isReceiptNoteReport,
	isReportInspectionNotConsistent,
	shouldAllowReportReopen,
	isAgeManagementReport
} from './businessRules'
import ReportHeader from './header'
import ReportTable from './table'
import ReportTitle from './title'
import { ReportsContext } from 'apps/wms/stores/ReportStore'

const ReportEditorModal = ({
	isOpen,
	onOutsideClick,
	report,
	reportType,
	reportPart,
	extractName,
	inspSelected,
	items,
	elements,
	loading,
	reportHeader,
	stage
}) => {
	const { t } = useTranslation()
	const {
		getReportData,
		getAgeManagementReportData,
		rejectReasonsList,
		user,
		rejectReport,
		rejectAgeMgmtReport,
		validateReport,
		validateAgeMgmtReport,
		threadCompoundTypes,
		applicationMethods,
		reopenMillReceipt
	} = useContext(ReportDataContext)

	const { updateReport, rejectInspection, validateInspection, reopenReport } = useContext(ReportsContext)

	const [consistencyStatusWarn, setConsistencyStatusWarn] = useState(false)
	const [allowEdit, setAllowEdit] = useState(false)
	const [allowReopen, setAllowReopen] = useState(false)
	const [hasChanges, setHasChanges] = useState(false)
	const [plannedQty, setPlannedQty] = useState(0)
	const [acceptedPipes, setAcceptedPipes] = useState(0)
	const [ltpa, setLtpa] = useState(null)
	const [ntQuantity, setNtQuantity] = useState(false)
	const [contentData, setContentData] = useState([])
	const [reportItems, setReportItems] = useState([])
	const [instrumentList, setInstrumentList] = useState({})
	const [reportSummary, setReportSummary] = useState({})
	const [filteredRejectReasons, setFilteredRejectReasons] = useState([])
	const [headersZoom, setHeadersZoom] = useState(1)
	const [tableZoom, setTableZoom] = useState(() => {
		const storagedTableZoom = JSON.parse(localStorage.getItem('@Shalyn:wms:zoom'))
		return storagedTableZoom ? storagedTableZoom : 0.5
	})
	const [threadColumns, setThreadColumns] = useState([])
	const [ageColumns, setAgeColumns] = useState([])
	const [entranceDateList, setEntranceDateList] = useState([])

	const [hasMeasure, setHasMeasure] = useState(false)
	const [hasThreadCompound, setHasThreadCompound] = useState(false)

	const window = useWindowSize()
	const modalDeadSpace = window.height >= 1440 ? 230 : 150
	const [tableHeight, setTableHeight] = useState(0)
	const parentRef = useRef(null)
	const headerRef = useRef(null)

	const [selectedReportPart, setSelectedReportPart] = useState(null)

	const currentHeaderHeight = useMemo(
		() => (headerRef.current !== null ? headerRef.current.offsetHeight : 0),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[headerRef, instrumentList, ltpa]
	)

	useEffect(() => {
		if (reportPart) {
			setSelectedReportPart(_.find(report.content, c => c.partnumber === reportPart))
		}
	}, [reportPart, report])

	useEffect(() => {
		const headzoom =
			window.height <= 540
				? 0.55
				: window.height <= 720
				? 0.85
				: window.height <= 960
				? 0.95
				: window.height <= 1080
				? 1
				: 1.2
		setHeadersZoom(headzoom)
	}, [window])

	useEffect(() => {
		isOpen && setTableHeight(parentRef.current.offsetHeight - modalDeadSpace - currentHeaderHeight)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, parentRef, currentHeaderHeight, window])

	useEffect(() => {
		if (selectedReportPart) {
			setAllowEdit(selectedReportPart?.validationdata?.status === REPORT_STATUS.code.PENDING)
		} else {
			setAllowEdit(report.status === REPORT_STATUS.code.PENDING)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report, reportType, selectedReportPart])

	useEffect(() => {
		const rejectList = rejectReasonsList.filter(rr => rr.inspectiontype === REPORT_TYPE.defectType[reportType])
		setFilteredRejectReasons(rejectList)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportType])
	useEffect(() => {
		const setDefault = () => {
			reportType !== null && report.id !== null && setReportItems(report.items || report.part_items)
			report.threadCompoundColumns &&
				report.threadCompoundColumns.length &&
				setThreadColumns(report.threadCompoundColumns)
			setHasMeasure(report.hasMeasure || report.part_has_measure)
			setHasThreadCompound(report.hasThreadCompound)
			if (report.content) setContentData(report.content)
			setInstrumentList(report.instrumentlist)
			setPlannedQty(report.total_quantity)
		}
		reportType !== null &&
			report.id !== null &&
			(isAgeManagementReport(reportType)
				? getAgeManagementReportData(report.id).then(list => {
						setEntranceDateList(list.entranceDateList)
						setAgeColumns(list.ageColumns)
						setReportItems(list.items)
						setLtpa(list.ltpa)
				  })
				: isReceiptNoteReport(reportType) && selectedReportPart
				? getReportData(reportType, report.id, selectedReportPart?.belcid).then(
						({ content, items, instrumentlist, summary }) => {
							setReportItems(items)
							if (content) setContentData(content)
							setInstrumentList(instrumentlist && instrumentlist.sort((a, b) => a.id - b.id))
							setReportSummary(summary)
						}
				  )
				: setDefault())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report, selectedReportPart])

	useEffect(() => {
		let nt = undefined
		const aux = contentData && contentData.reduce((prev, curr) => prev + curr.quantity, 0)
		aux && setPlannedQty(aux)
		contentData.length > 0 && contentData[0].nt_quantity !== null
			? (nt = contentData.reduce((prev, curr) => prev + curr.nt_quantity, 0))
			: (nt = reportItems.reduce((prev, curr) => prev + curr.part_nt_quantity, 0))
		nt != null && setNtQuantity(nt)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentData])

	useEffect(() => {
		const pipes =
			reportItems && reportItems.filter(report => report.finalreport === REPORT_ITEM_STATUS.code.ACCEPT).length
		setAcceptedPipes(pipes)
		isReceiptNoteReport(reportType)
			? setAllowReopen(true)
			: setAllowReopen(shouldAllowReportReopen(reportType, plannedQty, pipes))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportItems, plannedQty])

	useEffect(() => {
		let defects = []
		reportItems &&
			reportItems.length > 0 &&
			_.forEach(elements, el => {
				el &&
					_.forEach(el.defects, def => {
						defects.push(_.find(rejectReasonsList, reason => reason.id === def))
					})
			})
		defects = _.uniqBy(
			defects.filter(def => def !== undefined),
			'id'
		)
		setFilteredRejectReasons(defects)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportItems])

	const onDataChange = useCallback(
		report => {
			const i = reportItems.findIndex(item => item.valid === report.valid)
			if (i !== -1) {
				reportItems[i] = report
				setReportItems([...reportItems])
				setHasChanges(true)
			}
		},
		[reportItems]
	)

	const onAllDataChange = reportItems => {
		setReportItems([...reportItems])
		setHasChanges(true)
	}

	const onRejectReport = () => {
		isAgeManagementReport(reportType)
			? rejectAgeMgmtReport(report.id)
					.then(() => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
			: isReceiptNoteReport(reportType)
			? rejectReport(reportType, selectedReportPart?.belcid, selectedReportPart?.validationdata?.revision)
					.then(() => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
			: rejectInspection(report.partid, report.validation_revision)
					.then(() => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
	}

	const onUpdateReport = () => {
		let inconsistentReport = null
		_.forEach(reportItems, item => {
			let reportItemKeys = Object.keys(item)
			inconsistentReport = reportItemKeys.find(key => isReportInspectionNotConsistent(item, key))
		})
		inconsistentReport === undefined
			? updateReport(
					reportItems,
					reportType,
					report.partid ?? selectedReportPart?.belcid,
					report.validation_revision ?? selectedReportPart?.validationdata?.revision,
					hasThreadCompound
			  )
					.then(() => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
			: setConsistencyStatusWarn(true)
		return true
	}

	const onReopenReport = () => {
		isReceiptNoteReport(reportType)
			? reopenMillReceipt(selectedReportPart?.belcid, report.remarks, selectedReportPart?.validationdata?.revision)
					.then(() => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
			: reopenReport(report.partid, report.validation_revision)
					.then(() => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
	}

	const onValidateReport = () => {
		isAgeManagementReport(reportType)
			? validateAgeMgmtReport(report.id)
					.then(() => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
			: isReceiptNoteReport(reportType)
			? validateReport(reportType, selectedReportPart?.belcid, selectedReportPart?.validationdata?.revision)
					.then(() => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
			: validateInspection(report.partid, report.validation_revision)
					.then(() => onOutsideClick({ reload: true }))
					.catch(e => console.error(e))
	}

	const onZoomChange = value => {
		localStorage.setItem('@Shalyn:wms:zoom', JSON.stringify(value))
		setTableZoom(value)
	}

	return (
		<Modal isOpen={isOpen} onOutsideClick={onOutsideClick}>
			<Card
				ref={parentRef}
				borderRadius='0'
				backgroundColor='#F8F8F9'
				width='calc(100%)'
				height='calc(100%)'
				flex='1'
				margin='0'
				padding='0'
				alignItems='center'
				style={{ position: 'relative' }}>
				<FlexView width='calc(100% - 5vw - 32px)' padding='16px'>
					<FlexView ref={headerRef} width='100%'>
						<ReportActions
							acceptedPipes={acceptedPipes}
							plannedQty={plannedQty}
							report={report}
							reportType={reportType}
							allowEdit={allowEdit}
							hasChanges={hasChanges}
							allowReopen={allowReopen}
							onLeftReportEditor={onOutsideClick}
							onRejectReport={onRejectReport}
							onUpdateReport={onUpdateReport}
							onReopenReport={onReopenReport}
							onValidateReport={onValidateReport}
							zoom={tableZoom}
							onZoomChange={onZoomChange}
						/>
						<ReportTitle
							reportType={reportType}
							reportData={report}
							allowEdit={allowEdit}
							zoom={headersZoom}
							title={inspSelected ? inspSelected.name : extractName}
							reportPart={reportPart}
						/>
						<ReportHeader
							reportData={
								(reportType === REPORT_TYPE.code.AGE_EXTRACTS ||
									reportType === REPORT_TYPE.code.MILL_RECEIPT) &&
								reportHeader &&
								reportHeader.length
									? reportHeader
									: (reportType === REPORT_TYPE.code.AGE_EXTRACTS ||
											reportType === REPORT_TYPE.code.MILL_RECEIPT) &&
									  !reportHeader
									? {
											...report,
											...reportSummary,
											...(isReceiptNoteReport(reportType) && selectedReportPart
												? selectedReportPart
												: contentData[0])
									  }
									: report
							}
							instrumentList={instrumentList}
							ltpa={ltpa}
							zoom={headersZoom}
							untraceable={ntQuantity}
							reportType={reportType}
						/>
					</FlexView>
					<ReportTable
						entranceDateList={entranceDateList}
						ageColumns={ageColumns}
						threadColumns={threadColumns}
						stage={stage}
						inspSelected={inspSelected}
						items={items}
						hasMeasure={hasMeasure}
						hasThreadCompound={hasThreadCompound}
						reportType={reportType}
						reportItems={reportItems}
						elements={elements}
						rejectReasons={filteredRejectReasons}
						allowEdit={allowEdit}
						loading={loading}
						supervisor={`${user.firstName} ${user.lastName}`}
						onDataChange={onDataChange}
						onAllDataChange={onAllDataChange}
						height={tableHeight}
						zoom={tableZoom}
						manufacturer={threadCompoundTypes}
						applicationMethodList={applicationMethods}
					/>
				</FlexView>
				<AlertModal
					isOpen={consistencyStatusWarn}
					title={`${t('wms:UpdateError')} `}
					text={t(`wms:UpdateErrorText`)}
					confirmText='Ok'
					onConfirm={() => setConsistencyStatusWarn(false)}
				/>
			</Card>
		</Modal>
	)
}

export default ReportEditorModal
