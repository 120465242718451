/** @format */
/* eslint-disable react/prop-types */

import ActionDialog from 'apps/wms/components/forms/ActionModal'
import { Button, FlexView, Icon } from 'components/common'
import DialogModal from 'apps/wms/components/forms/DialogModal'
import Select from 'apps/wms/components/forms/Select'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { isAgeManagementReport, isReceiptNoteReport } from './businessRules'
import { REPORT_TYPE } from '../../utils/enum'

const ActionButton = styled(Button)`
	border-radius: 4px;
	font-size: 14px;
	line-height: 21px;
	padding: 4px 8px;
	font: Roboto;
	height: 40px;
	box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.13);
`

const ReportActions = ({
	report,
	reportType,
	allowEdit,
	hasChanges,
	allowReopen,
	onRejectReport,
	onUpdateReport,
	onReopenReport,
	onValidateReport,
	onLeftReportEditor,
	zoom,
	onZoomChange,
	acceptedPipes,
	plannedQty
}) => {
	const { t } = useTranslation()

	const [confirmRejection, setConfirmRejection] = useState(false)
	const [confirmUpdate, setConfirmUpdate] = useState(false)
	const [confirmReopen, setConfirmReopen] = useState(false)
	const [confirmValidate, setConfirmValidate] = useState(false)
	const [confirmReopenValidate, setConfirmReopenValidate] = useState(false)
	const [confirmLeave, setConfirmLeave] = useState(false)

	const partStr = t('wms:AndPart')
	const confirmValidateReportTextStr = t('wms:ConfirmValidateReportText')

	const handleLeftEditor = () => {
		allowEdit && hasChanges ? setConfirmLeave(true) : onLeftReportEditor()
	}

	const handleRejectReport = () => setConfirmRejection(true)

	const handleUpdateReport = () => setConfirmUpdate(true)

	const handleReopenReport = () => setConfirmReopen(true)

	const handleValidateReport = () => {
		allowReopen && acceptedPipes < plannedQty ? setConfirmReopenValidate(true) : setConfirmValidate(true)
	}

	const allowUpdate = () => !isAgeManagementReport(reportType) && !isReceiptNoteReport(reportType)

	const inspectionReportActionButtons = useMemo(
		() =>
			allowEdit && (
				<FlexView flexDirection='row'>
					<ActionButton
						backgroundColor='error'
						margin='auto 4px'
						color='white'
						onClick={() => handleRejectReport()}>
						<Icon name='cross' color='white' height='14px' width='14px' margin='0 8px 0 0' />
						{t('wms:RejectReport')}
					</ActionButton>
					{allowUpdate() && (
						<ActionButton
							backgroundColor='#6F5ED3'
							margin='auto 4px'
							color='white'
							onClick={() => handleUpdateReport()}>
							<Icon name='change' color='white' height='14px' width='14px' margin='0 8px 0 0' />
							{t('wms:UpdateReportAndRevision')}
						</ActionButton>
					)}
					{allowReopen ? (
						<ActionButton
							backgroundColor='#6F5ED3'
							margin='auto 4px'
							color='white'
							onClick={() => handleReopenReport()}>
							<Icon name='undo' color='white' height='14px' width='14px' margin='0 8px 0 0' />
							{isReceiptNoteReport(reportType) ? `${t('wms:Reopen')} ${t('wms:Report')}` : t('wms:OpenNewPart')}
						</ActionButton>
					) : null}
					<ActionButton
						backgroundColor='success'
						margin='auto 0px 0 4px'
						color='white'
						onClick={() => handleValidateReport()}>
						<Icon name='check' color='white' height='14px' width='14px' margin='0 8px 0 0' />
						{t('wms:ValidateReport')}
					</ActionButton>
				</FlexView>
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[allowEdit, allowReopen]
	)

	const getActionButtons = () => inspectionReportActionButtons

	const getConfirmRejectionTitle = useCallback(
		reportType => {
			let title = ''
			if (reportType === REPORT_TYPE.code.MILL_RECEIPT)
				title = `${t('wms:MillReceiptMessageConfirmation')} ${report.id} ${t('wms:MillReceiptMessageRejection')}`
			else {
				title = report.part
					? `${t('wms:ConfirmRejectReportText')} ${report.id} ${partStr} ${report.part}`
					: `${t('wms:ConfirmRejectReportText')} ${report.id}`
			}

			return title
		},
		[t, report.id, report.part, partStr]
	)

	const getConfirmRejectionText = useCallback(
		reportType => {
			let text = ''
			if (reportType === REPORT_TYPE.code.AGE_EXTRACTS) text = `${t('wms:ConfirmRejectListText')} ${report.title}?`
			else text = `${t('wms:ConfirmRejectWarn')}.`

			text += `\n${t('wms:TypeConfirmationText')}: ${t('wms:RejectReport').toUpperCase()}`
			return text
		},
		[t, report.title]
	)

	const zoomOptions = [
		{
			label: '150%',
			value: 1.5
		},
		{
			label: '125%',
			value: 1.25
		},
		{
			label: '110%',
			value: 1.1
		},
		{
			label: '100%',
			value: 1
		},
		{
			label: '80%',
			value: 0.8
		},
		{
			label: '75%',
			value: 0.75
		},
		{
			label: '60%',
			value: 0.6
		},
		{
			label: '50%',
			value: 0.5
		},
		{
			label: '35%',
			value: 0.35
		}
	]

	return (
		<FlexView
			flexDirection='row'
			justifyContent='space-between'
			width='100%'
			margin='0 0 16px 0'
			padding='0'
			alignItems='center'>
			<ActionButton backgroundColor='white' onClick={handleLeftEditor} margin='0'>
				<Icon name='arrow-left' color='gray' height='14px' width='14px' margin='8px' />
			</ActionButton>
			<FlexView margin='0 0 0 8px'>{t('wms:LeftReportEditor')}</FlexView>
			<Select
				placeholder={t('wms:MaterialRequisitionType')}
				value={zoom}
				options={zoomOptions}
				onChange={value => {
					onZoomChange(value)
				}}
				inline={true}
				margin='0 4px 0 auto'
				padding='4px 8px'
				fontSize='tiny'
			/>
			{getActionButtons()}
			<DialogModal
				isOpen={confirmRejection}
				title={getConfirmRejectionTitle(reportType)}
				text={getConfirmRejectionText(reportType)}
				confirmationText={t(`wms:RejectReport`).toUpperCase()}
				confirmText={t(`wms:ConfirmRejectReport`).toUpperCase()}
				onConfirm={onRejectReport}
				onCancel={() => setConfirmRejection(false)}
			/>
			<DialogModal
				isOpen={confirmUpdate}
				text={
					report.part
						? `${t('wms:ConfirmUpdateInspection')} ${report.id} ${partStr} ${report.part}`
						: `${t('wms:ConfirmUpdateInspection')} ${report.id}`
				}
				confirmText={t(`wms:Confirm`).toUpperCase()}
				onConfirm={() => onUpdateReport() && setConfirmUpdate(false)}
				onCancel={() => setConfirmUpdate(false)}
			/>
			<DialogModal
				isOpen={confirmReopen}
				title={`${t('wms:Reopen')} ${t('wms:Report')}`}
				text={
					isReceiptNoteReport(reportType)
						? `${t('wms:ReopenMillReceipt')} ${report.id}?`
						: `${t('wms:ConfirmOpenNewPart')} ?`
				}
				confirmText={t(`wms:Reopen`).toUpperCase()}
				onConfirm={onReopenReport}
				onCancel={() => setConfirmReopen(false)}
			/>
			<DialogModal
				isOpen={confirmValidate}
				text={
					isReceiptNoteReport(reportType)
						? `${confirmValidateReportTextStr} ${report.id}`
						: report.part
						? `${confirmValidateReportTextStr} ${report.id} ${partStr} ${report.part}`
						: `${confirmValidateReportTextStr} ${report.id}`
				}
				confirmText={t(`wms:Validate`)}
				onConfirm={onValidateReport}
				onCancel={() => setConfirmValidate(false)}
			/>
			<ActionDialog
				isOpen={confirmReopenValidate}
				title={`${t('wms:ValidateWithFewerItemsTitle')}`}
				text={`${t('wms:ValidateWithFewerItemsBodyInit')} ${report.id} ${t('wms:ValidateWithFewerItemsBody')}?`}
				confirmText={t(`wms:Validate`)}
				actionText={t(`wms:Reopen`)}
				cancelText={t(`wms:Back`)}
				onConfirm={onValidateReport}
				onAction={onReopenReport}
				onCancel={() => setConfirmReopenValidate(false)}
			/>
			<DialogModal
				isOpen={confirmLeave}
				title={t(`wms:DiscardChanges`)}
				text={t(`wms:DiscardChangesText`)}
				onConfirm={onLeftReportEditor}
				onCancel={() => setConfirmLeave(false)}
			/>
		</FlexView>
	)
}

export default ReportActions
