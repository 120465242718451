/** @format */

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { FlexView } from 'components/common'
import { ReportsContext } from '../stores/ReportStore'
import { REPORT_TYPE } from '../utils/enum'
import { useParams } from 'react-router-dom'
import { Select } from 'components/form'
import { WMSContext } from '../stores/WmsStore'
import ReviewReportList from '../containers/reports/ReviewList'
import ReviewReportInfo from '../containers/reports/ReviewInfo'
import PageTemplate from '../containers/common/PageTemplate'
import { useWindowSize } from '../utils/hooks'
import ReportEditorModal from '../containers/reportEditor/wrapper'
import { isAgeManagementReport, isReceiptNoteReport } from '../containers/reportEditor/businessRules'

const ReviewReportsPage = () => {
	const { t } = useTranslation()

	const { getEnduserList, warehouse } = useContext(WMSContext)

	const {
		reviewType,
		setReviewType,
		reviewReportsList,
		loadingReports,
		getAgeManagementReports,
		getMillReceipts,
		selectedRevReport,
		setSelectedRevReport,
		downloadAgeManagementReport,
		downloadMillReceipt,
		isEditorOpen,
		setIsEditorOpen,
		selectedExtract,
		setSelectedExtract,
		extracts,
		downloadingReport
	} = useContext(ReportsContext)

	const listDeadSpace = 0
	const window = useWindowSize()
	const [listHeight, setListHeight] = useState(0)
	const listRef = useRef(null)

	const [page, setPage] = useState(null)
	let { report } = useParams()

	const [endusers, setEndusers] = useState([])
	const [enduser, setEnduser] = useState(null)

	const [selectedReportPart, setSelectedReportPart] = useState(null)

	useEffect(() => {
		if (listRef === null || listRef.current === null) return
		setListHeight(listRef.current.offsetHeight - listDeadSpace)
	}, [listRef, window, enduser, selectedExtract])

	useEffect(() => {
		getEnduserList()
			.then(endusersList => endusersList && endusersList.length && setEndusers(endusersList))
			.catch(e => console.error(e))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	useEffect(() => {
		if (report === 'age_management') setReviewType(REPORT_TYPE.code.AGE_EXTRACTS)
		else setReviewType(REPORT_TYPE.code.MILL_RECEIPT)
		setPage(report)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report])

	useEffect(() => {
		setSelectedRevReport(null)
		if (page === 'age_management') {
			enduser && selectedExtract && getAgeManagementReports(enduser).catch(e => console.error(e))
		} else if (page === 'mill_receipts') {
			enduser && getMillReceipts(enduser).catch(e => console.error(e))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enduser, selectedExtract, warehouse])

	useEffect(() => {
		setEnduser(null)
		setSelectedExtract(null)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [warehouse])

	const handleSelectReport = useCallback(
		(reportId, partNumber) => {
			if (reportId === null) return

			if (page === 'age_management') {
				let name = _.find(extracts, ex => ex.extractid === selectedExtract)
				setSelectedRevReport({
					...reviewReportsList.find(item => item.id === reportId),
					enduser: enduser,
					title: name ? name.extractdesc : null
				})
				setSelectedReportPart(null)
				return
			}

			setSelectedRevReport({
				...reviewReportsList?.find(item => item.id === reportId),
				enduser: enduser
			})
			setSelectedReportPart(partNumber)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[reviewReportsList]
	)

	const handleDownloadReport = (reportId, partNumber) => {
		if (page === 'age_management') {
			reportId && downloadAgeManagementReport(reportId, reviewType).catch(e => console.error(e))
		} else if (page === 'mill_receipts') {
			reportId && downloadMillReceipt(reportId, partNumber).catch(e => console.error(e))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	const handleOpenReportEditor = useCallback((reportId, partNumber) => {
		if (isReceiptNoteReport(reviewType)) setSelectedReportPart(reportId, partNumber)
		setIsEditorOpen(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const closeReportEditorModal = reload => {
		setIsEditorOpen(false)
		setSelectedReportPart(null)
		const hydrate = async () => {
			try {
				isAgeManagementReport(reviewType) ? await getAgeManagementReports(enduser) : await getMillReceipts(enduser)
				handleSelectReport(selectedRevReport.id)
			} catch (e) {
				console.error(e)
			}
		}
		return reload ? hydrate() : null
	}

	const getPageName = useCallback(() => {
		return page === 'age_management' ? t('wms:AgeManagementReports') : t('wms:ReceiptNote')
	}, [page, t])

	const renderName = () => {
		let extract = extracts && _.find(extracts, ex => ex.extractid === selectedExtract)

		return extract ? extract.extractdesc : null
	}

	const crumbs = useMemo(
		() => [
			{
				name: 'WMS',
				path: '/wms/'
			},
			{
				name: getPageName(),
				path: null
			}
		],
		[getPageName]
	)

	return (
		<PageTemplate crumbs={crumbs} overflow={isEditorOpen ? 'hidden' : 'inherit'}>
			<FlexView width='100%' maxWidth='calc(100% - 220px)' flex='1' padding='0 0 40px 0'>
				<FlexView minWidth='100%' margin='0 0 24px 0' justifyContent='space-between'>
					<FlexView
						data-cy='flexview-page-title'
						minWidth='100%'
						fontSize='40px'
						fontWeight='bold'
						margin='0'
						justifyContent='space-between'>
						{getPageName()}
					</FlexView>
					<FlexView flexDirection='row' width='100%'>
						<Select
							label={`${t('wms:EndUser')}:`}
							placeholder={t(`wms:EndUser`)}
							value={enduser}
							options={endusers.map(({ id, name }) => ({
								value: id,
								label: name
							}))}
							onChange={value => {
								setEnduser(value)
							}}
							width='auto'
							inline={true}
							margin='8px 0 0 0'
							searchable
							data-cy='select-source-enduser'
						/>
						{page === 'age_management' && extracts && (
							<Select
								label={t('wms:ExtractName')}
								placeholder={t('wms:ExtractName')}
								value={selectedExtract}
								options={
									extracts &&
									_.map(extracts, ex => {
										return { value: ex.extractid, label: ex.extractdesc }
									})
								}
								onChange={value => {
									setSelectedExtract(value)
								}}
								width='auto'
								inline={true}
								margin='8px 0 0 16px'
								data-cy='select-extract'
							/>
						)}
					</FlexView>
				</FlexView>
				<FlexView width='100%' flex='1' flexDirection='row'>
					{enduser && selectedExtract && page === 'age_management' ? (
						<FlexView height='100%' width='65%' ref={listRef} margin='0 8px 0 0'>
							<ReviewReportList
								loadingList={loadingReports}
								list={reviewReportsList}
								reviewType={reviewType}
								onDownloadReport={handleDownloadReport}
								onSelectReport={handleSelectReport}
								onCheckReport={handleOpenReportEditor}
								height={`${listHeight}px`}
								downloadingReport={downloadingReport}
								data-cy='review-report-list'
							/>
						</FlexView>
					) : enduser && page === 'mill_receipts' ? (
						<FlexView height='100%' width='65%' ref={listRef} margin='0 8px 0 0'>
							<ReviewReportList
								loadingList={loadingReports}
								list={reviewReportsList}
								reviewType={reviewType}
								onDownloadReport={handleDownloadReport}
								onSelectReport={handleSelectReport}
								onCheckReport={handleOpenReportEditor}
								height={`${listHeight}px`}
								data-cy='review-report-list'
							/>
						</FlexView>
					) : (
						<FlexView
							width='100%'
							fontWeight='bold'
							fontSize='24px'
							color='lightGray'
							margin='auto 0'
							alignItems='center'
							justifyContent='center'
							data-cy='placeholder-reports-list'>
							{page === 'mill_receipts' ? t('wms:SelectEnduser') : t('wms:SelectEndUserAndExtract')}
						</FlexView>
					)}
					<FlexView flex='1' height='100%' margin='0 0 0 8px'>
						{selectedRevReport ? (
							<ReviewReportInfo
								reportInfo={selectedRevReport}
								reportPart={selectedReportPart}
								reviewType={reviewType}
								onCheckReport={value => handleOpenReportEditor(value)}
								onDownloadReport={handleDownloadReport}
								loadingInfo={false}
								extractName={renderName()}
								downloadingReport={downloadingReport}
								data-cy='inspection-report-info'
							/>
						) : null}
					</FlexView>
				</FlexView>
			</FlexView>
			{selectedRevReport && isEditorOpen && (
				<ReportEditorModal
					isOpen={isEditorOpen}
					onOutsideClick={closeReportEditorModal}
					report={selectedRevReport}
					reportType={reviewType}
					reportPart={selectedReportPart}
					extractName={renderName()}
				/>
			)}
		</PageTemplate>
	)
}

export default ReviewReportsPage
